import { Injectable } from '@angular/core';
import { UserService } from '@fitech-workspace/auth-lib';
import { DateUtils } from '@fitech-workspace/core-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ShiftsRepository } from '../../management/repositories/shifts.repository';
import { Shift } from '../models/date-picker/shift.model';

@Injectable({
	providedIn: 'root',
})
export class DatePickerService {
	private _shiftsSubject = new BehaviorSubject<Shift[]>(null);

	get shifts$(): Observable<Shift[]> {
		return this._shiftsSubject.asObservable();
	}

	get loadedShifts$(): Observable<Shift[]> {
		return this.shifts$.pipe(filter((shifts: Shift[]) => !!shifts));
	}

	constructor(private _repository: ShiftsRepository, private _userService: UserService) {}

	getShiftsFromApi(): void {
		const clientId = this._userService?.currentUser?.clientId;
		if (clientId) {
			this._repository.getByClient(clientId).subscribe((res: any[]) => {
				const shifts = res
					.map((x: any): Shift => {
						return {
							id: x.id,
							order: x.order,
							name: x.name,
							timeFrom: x.timeFrom,
							timeTo: x.timeTo,
						};
					})
					.sort((a: Shift, b: Shift) => a.order - b.order);
				this._shiftsSubject.next(shifts);
			});
		}
	}

	getCurrentShift(shifts: Shift[]): Shift | null {
		if (!shifts) {
			return;
		}

		const currentTime = DateUtils.getCurrentFullTime();

		for (const shift of shifts) {
			if (currentTime >= shift.timeFrom && currentTime < shift.timeTo) {
				return shift;
			}
		}

		return null;
	}
}
