import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthLibModule } from '@fitech-workspace/auth-lib';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { FisenseCommonLibModule } from '@fitech-workspace/fisense-common-lib';
import { SharedUiButtonLibModule } from '@fitech-workspace/shared/ui/button-lib';
import { SharedUiChartLibModule } from '@fitech-workspace/shared/ui/chart-lib';
import { SharedUiInputsMdModule, SharedUiInputsModule } from '@fitech-workspace/shared/ui/controls-lib';
import { SharedUiGridLibModule } from '@fitech-workspace/shared/ui/grid-lib';
import { SharedUiLayoutLibModule } from '@fitech-workspace/shared/ui/layout-lib';
import { SharedUiGridsAddonsModule, SharedUiGridsCoreModule } from '@fitech-workspace/shared/ui/map-lib';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { VersionsDialogComponent } from './components/versions-dialog/versions-dialog.component';
import { CustomDatePickerComponent } from './date-pickers/custom-date-picker/custom-date-picker.component';
import { CustomDatePickerInputComponent } from './date-pickers/custom-date-picker-input/custom-date-picker-input.component';
import { CustomDateSwitchComponent } from './date-pickers/custom-date-switch/custom-date-switch.component';
import { MonthYearPickerComponent } from './date-pickers/month-year-picker/month-year-picker.component';
import { WeekRangeSelectionComponent } from './date-pickers/week-range-selection/week-range-selection.component';
import { YearPickerComponent } from './date-pickers/year-picker/year-picker.component';
import { DefaultOrderKeyValuePipe } from './pipes/default-order-key-value.pipe';
import { PercentPiecesPipe } from './pipes/percent-pieces.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { BaseCheckboxComponent } from './selectors-components/base-checkbox/base-checkbox.component';
import { BaseSelectComponent } from './selectors-components/base-select/base-select.component';
import { BaseSwitchComponent } from './selectors-components/base-switch/base-switch.component';
import { ClientsSelectorComponent } from './selectors-components/clients-selector/clients-selector.component';
import { ComponentsSelectorComponent } from './selectors-components/components-selector/components-selector.component';
import { DesignatorsSelectorComponent } from './selectors-components/designators-selector/designators-selector.component';
import { LineSelectorQlComponent } from './selectors-components/line-selector-ql/line-selector-ql.component';
import { LinesListSelectorComponent } from './selectors-components/lines-list-selector/lines-list-selector.component';
import { PointStatusSelectorComponent } from './selectors-components/point-status-selector/point-status-selector.component';
import { ProductsListSelectorComponent } from './selectors-components/products-list-selector/products-list-selector.component';
import { ProductsSelectorComponent } from './selectors-components/products-selector/products-selector.component';
import { SensorListSelectorComponent } from './selectors-components/sensor-list-selector/sensor-list-selector.component';
import { SensorsGroupsListSelectorComponent } from './selectors-components/sensors-groups-list-selector/sensors-groups-list-selector.component';
import { SensorsSelectorComponent } from './selectors-components/sensors-selector/sensors-selector.component';

@NgModule({
	declarations: [
		BaseSwitchComponent,
		SensorsGroupsListSelectorComponent,
		ProductsSelectorComponent,
		DesignatorsSelectorComponent,
		PointStatusSelectorComponent,
		SensorsSelectorComponent,
		ProductsListSelectorComponent,
		BaseCheckboxComponent,
		ClientsSelectorComponent,
		BaseSelectComponent,
		CustomDatePickerComponent,
		WeekRangeSelectionComponent,
		MonthYearPickerComponent,
		YearPickerComponent,
		ComponentsSelectorComponent,
		PrettyJsonPipe,
		LinesListSelectorComponent,
		PercentPiecesPipe,
		DefaultOrderKeyValuePipe,
		CommonHeaderComponent,
		CustomDatePickerInputComponent,
		CustomDateSwitchComponent,
		LineSelectorQlComponent,
		VersionsDialogComponent,
		CustomDateSwitchComponent,
		LineSelectorQlComponent,
		SensorListSelectorComponent,
	],
	imports: [
		CommonModule,
		CoreLibModule,
		SharedUiGridLibModule,
		SharedUiGridsCoreModule,
		SharedUiGridsAddonsModule,
		SharedUiLayoutLibModule,
		SharedUiChartLibModule,
		SharedUiInputsModule,
		SharedUiInputsMdModule,
		SharedUiButtonLibModule,
		AuthLibModule,
		NgbPopoverModule,
		FisenseCommonLibModule,
	],
	exports: [
		BaseSwitchComponent,
		SensorsGroupsListSelectorComponent,
		ProductsSelectorComponent,
		DesignatorsSelectorComponent,
		PointStatusSelectorComponent,
		SensorsSelectorComponent,
		ProductsListSelectorComponent,
		BaseCheckboxComponent,
		ClientsSelectorComponent,
		BaseSelectComponent,
		CustomDatePickerComponent,
		WeekRangeSelectionComponent,
		MonthYearPickerComponent,
		ComponentsSelectorComponent,
		PrettyJsonPipe,
		LinesListSelectorComponent,
		PercentPiecesPipe,
		DefaultOrderKeyValuePipe,
		CommonHeaderComponent,
		CustomDatePickerInputComponent,
		CustomDateSwitchComponent,
		LineSelectorQlComponent,
		VersionsDialogComponent,
		CustomDateSwitchComponent,
		LineSelectorQlComponent,
		SensorListSelectorComponent,
	],
})
export class SharedModule {}
