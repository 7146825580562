import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { StringUtils } from './string-utils';
import { DecimalPrecision } from '../models/decimal-precision.model';

export class CustomValidators {
	/**
	 * @description
	 * Validator that requires at least one control to be selected.
	 *
	 * @returns An error map with the `{ minOneRequired: true }` property
	 * if the validation check fails, otherwise `null`.
	 */
	static atLeastOneCheckboxSelected(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const formGroup: UntypedFormGroup = control as UntypedFormGroup;

			const isAnySelected: boolean =
				Object.keys(formGroup.controls).filter((key: string): boolean => formGroup.controls[key].value as boolean).length > 0;
			return isAnySelected ? null : { minOneRequired: true };
		};

		return validatorFn;
	}

	/**
	 * @description Validator that requires value to be comma separated list of N numbers (can be float point).
	 *
	 * @param listLength Allowed number of items in comma separated list.
	 * @param multiples Decides whether validator should check if list has exact number of items according to listLength param or its multiples.
	 *
	 * @returns An error map with the `{ listInvalid: true }` property if the validation check fails, otherwise `null`.
	 */
	static isCommaSeparatedListOfIntOrFloatPointNumbers(listLength: number = 3, multiples: boolean = false): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const splitted = control.value?.split(',');
			const multiplesCondition: boolean = multiples ? splitted?.length % listLength === 0 : splitted?.length === listLength;
			const floatPointRegExp = /^\s*[-]?\d+(\.\d+)?\s*$/;

			// added condition splitted[0] === '' for fix group edit in components
			return (multiplesCondition && !splitted.some((x: string) => StringUtils.isNullOrWhiteSpace(x) || !floatPointRegExp.test(x) || isNaN(Number(x)))) ||
				splitted[0] === ''
				? null
				: { listInvalid: true };
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator requires that the control does not contain any white space.
	 *
	 * @returns An error map with the `{ isAnyWhiteSpace: true }` property
	 * if the validation check fails, otherwise `null`.
	 */
	static noWhitespace(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
			const regex = /\s/;

			const isAnyWhiteSpace = regex.test(control.value);

			return isAnyWhiteSpace ? { isAnyWhiteSpace: true } : null;
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator requires that the control does not contain any white space at the front or at the end.
	 *
	 * @returns An error map with the `{ isLeadingOrTrailingWhitespace: true }` property
	 * if the validation check fails, otherwise `null`.
	 */
	static noLeadingOrTrailingWhitespace(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
			const regex = /^\s+|\s+$/g;

			const isAnyWhiteSpace = regex.test(control.value);

			return isAnyWhiteSpace ? { isLeadingOrTrailingWhitespace: true } : null;
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator requires that the control does not contain only white space.
	 *
	 * @returns An error map with the `{ isOnlyWhiteSpace: true }` property
	 * if the validation check fails, otherwise `null`.
	 */
	static noWhitespaceOnly(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
			const isOnlyWhitespace = control.value?.trim().length === 0;
			return isOnlyWhitespace ? { isOnlyWhiteSpace: true } : null;
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator that requires the control value be different from provided ones.
	 *
	 * @returns An error map with the `notUnique` property
	 * if the validation check fails, otherwise `null`.
	 */
	static unique(existingValues: string[], caseSensitive: boolean = true): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			let controlValue: string = control.value.trim();
			if (!caseSensitive) {
				controlValue = controlValue.toLowerCase();
			}
			const notUnique: boolean = existingValues.some((value: string): boolean => {
				let otherValue: string = value.trim();
				if (!caseSensitive) {
					otherValue = otherValue.toLowerCase();
				}
				return otherValue === controlValue;
			});
			return notUnique ? { notUnique: { valid: false, value: control.value } } : null;
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator that requires the control value to be positive number.
	 *
	 * @returns An error map with the `notPositiveNumber` property
	 * if the validation check fails, otherwise `null`.
	 */
	static nonNegativeNumber(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const value: string = control?.value?.toString();

			return value && value.startsWith('-') ? { notPositiveNumber: { min: 0 } } : null;
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator that requires the control value to be properly defined number.
	 *
	 * @returns An error map with the `invalidNumberFormat` property
	 * if the validation check fails, otherwise `null`.
	 */
	static validNumberFormat(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const value: string = control?.value?.toString();
			const regex = /^-?\d+(\.\d+)?$/;
			const valid = regex.test(value);

			return value && !valid ? { invalidNumberFormat: true } : null;
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator that requires the control value not to be empty or null.
	 *
	 * @returns An error map with the `required` property
	 * if the validation check fails, otherwise `null`.
	 */
	static noNullOrEmptyValue(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const value: string = control?.value?.toString();

			return !value || value?.trim() === '' ? { required: true } : null;
		};

		return validatorFn;
	}

	/**
	 * @description
	 * Validator that requires the control value to be a decimal number with provided precision.
	 *
	 * @returns An error map with the `invalidDecimalPrecision` property
	 * if the validation check fails, otherwise `null`.
	 */
	static correctDecimalNumber(decimalPrecision: DecimalPrecision): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const { precision, scale } = decimalPrecision;

			if (precision <= scale) {
				return { invalidDecimalPrecision: true };
			}

			const regexp1 = new RegExp(`(^[\\d]{${precision - scale}})[\\d]`, 'g');
			const regexp2 = new RegExp(`(\\.[\\d]{${scale}}).`, 'g');
			const isInvalid = regexp1.test(control.value) || regexp2.test(control.value);

			return isInvalid ? { invalidDecimalPrecision: true } : null;
		};

		return validatorFn;
	}
}
